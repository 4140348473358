import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import ContactForm from "components/Contact-form/contact-form";


const BlogDetailsDark = () => {

 
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Transform Your Business with Elfonze Strategic B2B Alliances!"
        paragraph="Ready to elevate your business? Partner with Elfonze to unlock new growth opportunities, streamline operations and enhance your competitive edge. Our strategic B2B alliances in staffing, product engineering, services and more offer! "
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/blogs/Blog_9/BP-01.jpg" alt="B2B partnership alliance" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">

                          <h2> - Expanded Market Reach</h2>

                          <p className="">
                              Tap into new markets and reach a wider audience through our offices and network across India, USA, Europe, Middle East and South APAC countries.
                          </p>

                          <h2> - Shared Expertise and Resources</h2>

                          <p className="">
                            Access specialized skills and resources in staffing, product engineering and services, reducing costs and accelerating innovation.   
                          </p>

                          <h2> - Superior Product Offerings </h2>

                          <p className="">
                            Collaborate on product development and IT to meet market demands with superior solutions. 
                          </p>

                          <h2> - Efficient Processes </h2>

                          <p className="">
                            Benefit from our talented pool of employees, efficient screening and selection process and quick turnaround time to generate your needs effectively. 
                          </p>

                          <h2> - Enhanced Competitive Position</h2>

                          <p className="">
                            Combine strengths to outpace competitors and lead the industry.
                          </p>

                          <br/>
                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/blogs/Blog_9/BP-02.jpg" alt="Your Vision, Our Craft: Design, Develop and Market for Digital Success" />
                              </div>
                            </div>
                          </div>
                          <br/>

                          <h2>Why Choose Elfonze ? </h2> 

                          <p className="">
                          Proven success in fostering strategic alliances across various sectors with years of expertise. 
                          Dedicated team of domain experts to nurture and grow the partnership. 
                          Customized approach to fit your unique needs in staffing, product engineering and services.
                          </p>
 
                          <h2>Don't Miss Out!</h2>

                          <p className="">
                          Contact us today to discover how a strategic B2B alliance can revolutionize your business in staffing, product engineering, services and beyond.  
                          <br/><br/>Let's build the future together.
                          </p>                         
                          
                          <div className="line bottom left w-100"></div>
                          <div className="share-inf">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                              {/* <a href="#0">Web</a>,<a href="#0">Themeforest</a>,
                              <a href="#0">ThemesCamp</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <ContactForm fullWidthFormVisibility={true} />
          </div>                    

        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Transform Your Business with Elfonze Strategic B2B Alliances!</title>
      <meta key="description" name="description" 
        content="Transform Your Business with Elfonze Strategic B2B Alliances!" />
    </>
  )
}

export default BlogDetailsDark;
